<template>
  <div class="accent">
    <v-container fluid>
      <v-row class="mx-auto mt-5 pb-5">
        <v-col cols="12">
          <h1>
            <router-link :to="{ name: 'AssessmentCenter' }"
              >Assessment center</router-link
            >
            | <span class="title"> Access Panel</span>
          </h1>
        </v-col>
      </v-row>
      <v-container>
        <v-row class="mt-10">
          <v-col class="mx-auto" cols="12" xl="10">
            <app-action-panel />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
    appActionPanel: () => import('@/components/assessment-center/actionPanel'),
  },
  props: {
    source: String,
  },
}
</script>
